const headline = {
  '3xl': {
    size: '61px',
    leading: '1.5',
    weight: '400',
    font: 'primary',
  },
  '2xl': {
    size: '48px',
    leading: '1.25',
    weight: '400',
    font: 'primary',
  },
  xl: {
    size: '39px',
    leading: '1.2',
    weight: '400',
    font: 'primary',
  },
  lg: {
    size: '32px',
    leading: '1.2',
    weight: '400',
    font: 'primary',
  },
  md: {
    size: '25px',
    leading: '1.2',
    weight: '400',
    font: 'primary',
  },
  sm: {
    size: '20px',
    leading: '1.2',
    weight: '400',
    font: 'primary',
  },
  xs: {
    size: '16px',
    leading: '1.5',
    weight: '400',
    font: 'primary',
  },
  '2xs': {
    size: '13px',
    leading: '1.5',
    weight: '400',
    font: 'primary',
  },
};

const preheadline = {
  md: {
    size: '16px',
    leading: '1.3125',
    weight: '400',
    font: 'primary',
  },
};

const preamble = {
  md: {
    size: '20px',
    leading: '1.3',
    weight: '700',
    font: 'primary',
  },
  sm: {
    size: '16px',
    leading: '1.5',
    weight: '700',
    font: 'primary',
  },
};

const body = {
  md: {
    size: '20px',
    leading: '1.3',
    weight: '400',
    font: 'secondary',
  },
  sm: {
    size: '16px',
    leading: '1.5',
    weight: '400',
    font: 'secondary',
  },
};

const typography = {
  headline: {
    DEFAULT: headline.md,
    ...headline,
  },
  preheadline: {
    DEFAULT: preheadline.md,
    ...preheadline,
  },
  preamble: {
    DEFAULT: preamble.md,
    ...preamble,
  },
  body: {
    DEFAULT: body.md,
    ...body,
  },
};

module.exports = typography;
