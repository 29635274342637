const typographyPlugin = require('tailwind-utils/typography.plugin');

module.exports = (typographyConfig) => (theme) => {
  const typograph = (name) => typographyPlugin.getUtilities(theme, typographyConfig)[`.text-${name}`];

  return {
    DEFAULT: {
      css: {
        '--tw-prose-body': theme('colors.black'),
        '--tw-prose-headings': theme('colors.black'),
        '--tw-prose-links': theme('colors.cherry-blossom.500'),
        '--tw-prose-bullets': theme('colors.black'),
        '--tw-prose-counters': theme('colors.black'),

        'max-width': '100%',

        ...typograph('body-md'),

        h1: {
          ...typograph('headline-md'),
          marginTop: theme('spacing[5]'),
          marginBottom: theme('spacing[4]'),

          '@media screen(sm)': typograph('headline-lg'),
        },

        h2: {
          ...typograph('headline-sm'),
          marginTop: theme('spacing[5]'),
          marginBottom: theme('spacing[4]'),

          '@media screen(sm)': typograph('headline-md'),
        },

        h3: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('headline-sm'),
        },

        h4: {
          ...typograph('headline-xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[3]'),
        },

        h5: {
          ...typograph('headline-2xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[3]'),
        },

        h6: {
          ...typograph('headline-2xs'),
          marginTop: theme('spacing[4]'),
          marginBottom: theme('spacing[3]'),
        },

        p: {
          ...typograph('body-sm'),
          marginTop: theme('spacing[3]'),
          marginBottom: theme('spacing[3]'),

          '@media screen(sm)': typograph('body-md'),
        },

        a: {
          textDecoration: 'underline',
          fontWeight: 700,

          '&:hover': {
            color: theme('colors.cherry-blossom.300'),
          },

          '&:visited': {
            color: theme('colors.cannon-pink.600'),
          },
        },

        ol: {
          marginBottom: theme('spacing[3]'),
          paddingLeft: theme('spacing[4]'),
        },

        ul: {
          marginBottom: theme('spacing[3]'),
          paddingLeft: theme('spacing[4]'),
        },

        li: {
          ...typograph('headline-xs'),
          margin: theme('spacing[1]'),
          paddingLeft: 0,

          '@media screen(sm)': typograph('headline-sm'),
        },
      },
    },

    factbox: {
      css: {
        ...typograph('headline-xs'),

        p: typograph('headline-xs'),
      },
    },

    coverbox: {
      css: {
        ...typograph('headline-2xs'),

        p: {
          ...typograph('headline-2xs'),
          marginBottom: 0,
        },

        li: typograph('headline-2xs'),
      },
    },
  };
};
