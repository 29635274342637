const gridSystemPlugin = require('tailwind-utils/gridSystem.plugin');
const typographyPlugin = require('tailwind-utils/typography.plugin');
const { withBackgroundColorVariants } = require('tailwind-utils/withColorVariants');

const colorsConfig = require('./configs/tailwind.colors');
const typographyConfig = require('./configs/tailwind.typography');
const proseConfig = require('./configs/tailwind.prose');

const fontFamily = {
  primary: 'var(--font-primary), Arial, sans-serif',
  secondary: 'var(--font-secondary), Arial, sans-serif',
};

/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      fontFamily,
      colors: colorsConfig,

      typography: proseConfig(typographyConfig),
    },
  },
  safelist: [...withBackgroundColorVariants(['red-500', 'sand-100', 'sand-200'])],

  plugins: [gridSystemPlugin(), typographyPlugin(typographyConfig)],
};
